import { MASK_SQUIRCLE } from '../constants/images';

import { Image } from '../layout/Image';
import { BodyText } from '../typography/BodyText';
import { DisplayHeading, DisplayHeadingProps } from '../typography/DisplayHeading';

type ItemProps = {
  /**
   * Item Title
   */
  title?: string;
  /**
   * Item Description
   */
  description?: string;
  /**
   * Image URL
   */
  imageUrl?: string;
  /**
   * Image Alt. If none specified, defaults to Item Title)
   */
  imageAlt?: string;
};

type UspGridProps = {
  items: ItemProps[];
  /**
   * Item Title's element to render as
   */
  titleAs?: DisplayHeadingProps['as'];
};

/**
 * Used in Custom Site Page
 */

export const UspGrid = ({ items, titleAs }: UspGridProps) => {
  if (!items?.length) return null;

  return (
    <ul
      data-testid="usp-grid"
      className="grid grid-cols-2 gap-x-4 gap-y-6 lg:grid-cols-3 lg:gap-x-10 lg:gap-y-8"
    >
      {items.map(({ title, description, imageUrl, imageAlt }: ItemProps) => (
        <li key={title} className="m-0 flex flex-col p-0 text-center">
          <Image
            dataTestId="hero-style-04-image-mobile"
            style={{
              WebkitMaskImage: `url(${MASK_SQUIRCLE})`,
              WebkitMaskSize: 'cover',
              WebkitMaskRepeat: 'no-repeat',
            }}
            className="image:object-cover image:object-center mx-auto my-0 h-[90px] w-[90px]"
            src={imageUrl}
            alt={imageAlt ?? title}
            fill
          />

          {title && (
            <DisplayHeading
              as={titleAs || 'h4'}
              data-testid="usp-grid-title"
              size="4"
              leading="normal"
              className="text-neutral-strong overflow-wrap-anywhere mt-4 font-semibold lg:text-xl lg:font-bold lg:leading-normal"
            >
              {title}
            </DisplayHeading>
          )}
          {description && (
            <BodyText
              data-testid="usp-grid-description"
              className="overflow-wrap-anywhere m-0 mt-2 hidden lg:block"
            >
              {description}
            </BodyText>
          )}
        </li>
      ))}
    </ul>
  );
};
