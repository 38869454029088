import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';

type ImageType = {
  src: string;
  alt: string;
};

type ImageRowProps = {
  images: ImageType[];
  priority?: boolean;
  priorityCount?: number;
};

// This is actually defining rotate position for the given index (key)
const rotateMap = {
  1: 'rotate-2',
  2: '-rotate-3',
  3: 'rotate-1',
  4: '-rotate-2',
  5: 'rotate-2',
};

export const ImageRow = ({ images, priority = false, priorityCount = null }: ImageRowProps) => {
  const slicedImages = images?.slice(0, 5);

  // Require images to be given, and to be atleast 5 (design specifics)
  if (!images?.length || images.length < 5) return null;

  return (
    <div className="mx-auto my-0 max-w-[1440px] overflow-hidden xl:overflow-visible">
      <div data-testid="image-row-container" className="-mx-6 my-10 mb-0 flex gap-2 py-4 md:gap-10">
        {slicedImages.map((image, index) => {
          const mobileHidden = index === 0 || index > 3;
          return (
            <Image
              className={cn(
                'aspect-[16/11] rounded shadow-md',
                rotateMap[index + 1],
                mobileHidden && 'hidden',
                'md:block'
              )}
              data-testid={mobileHidden ? 'image-row-mobile-hidden' : 'image-row-image'}
              key={`${image.src}-${index}`} // this will raise sonarcloud warning, but prismic doesn't provide a unique option to render those, this should get fixed when rest of the sonarcloud warnings gets fixed with the common solution
              src={image.src}
              alt={image.alt}
              fill
              priority={priority && priorityCount !== null && index <= priorityCount}
            />
          );
        })}
      </div>
    </div>
  );
};
