import React from 'react';
import { ImageRow as Component } from '@virginexperiencedays/components-v2/src/banners/ImageRow';

import { LCP_PREFETCH_CAROUSEL_COUNT, LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import { PrismicImage } from '../../types';
import { PrismicContext } from '../../types/context';

type ImageRowSliceProps = {
  slice: {
    items: {
      image: PrismicImage;
    }[];
    position?: number;
  };
  context: PrismicContext;
};

// Design specific requirements (slicing to only 5 images happens in the UI component itself, can be moved here if it feels wrong to embed design/business logic in the UI component)
const ImageRow = ({ slice, context }: ImageRowSliceProps) => {
  const { isFaceted } = context;
  const { items, position } = slice;
  const filteredImages = items?.filter((image) => image.image.url);

  if (isFaceted || !items?.length) {
    return null;
  }

  // Determines whether images should be prioritised for LCP preloading
  const priority = position <= LCP_PREFETCH_SLICE_COUNT;

  return (
    <Component
      images={filteredImages.map((item) => ({
        src: item.image.url,
        alt: item.image.alt ?? 'Illustration',
      }))}
      priority={priority}
      priorityCount={LCP_PREFETCH_CAROUSEL_COUNT}
    />
  );
};

export default ImageRow;
