import { CSSProperties, Key } from 'react';
import { cn } from '@virginexperiencedays/tailwind';

import { Image, type ImageProps } from '../../layout/Image';
import { Container } from '../../layout/Container';
import { LinkWrap } from '../../navigation/LinkWrap';

type ImageItemProps = {
  alignment: string;
  image: { url: string; alt?: string; dimensions: { width: number; height: number } };
  link?: { link_type?: string; url?: string; slug?: string; target?: string };
  max_width_desktop?: number;
  max_width_tablet?: number;
};

export type CustomImageProps = {
  items: ImageItemProps[];
};

export const CustomImage = ({ items }: CustomImageProps) => {
  if (!items?.length) return null;

  return (
    <Container dataTestId="custom-image-slice">
      <div className="flex flex-col gap-4">
        {items.map((item: ImageItemProps, i: Key) => (
          <ImageItem key={i} {...item} />
        ))}
      </div>
    </Container>
  );
};

const ImageItem = (data?: ImageItemProps) => {
  if (!data || !data?.image?.url) return null;

  const {
    image,
    alignment,
    link = { link_type: null, url: null, slug: null, target: null },
    max_width_tablet,
    max_width_desktop,
  } = data;

  const { url: src, alt, dimensions: { width, height } = { width: 0, height: 0 } } = image;
  const { link_type: linkType, url, slug, target } = link;

  const aspectRatio = width && height ? `${width} / ${height}` : null;
  const maxWidthTablet = !isNaN(Number(max_width_tablet)) ? max_width_tablet : undefined;
  const maxWidthDesktop = !isNaN(Number(max_width_desktop)) ? max_width_desktop : undefined;

  const imageAlignment = {
    right: 'ml-auto',
    center: 'mx-auto',
    left: 'ml-0',
  };

  if (!src) return null;

  return (
    <div
      data-testid="custom-image-item"
      className={cn(
        'relative w-full overflow-hidden',
        '[&_a]:transition [&_a]:duration-150 [&_a]:ease-in-out [&_a]:hover:opacity-90',
        imageAlignment[alignment],
        maxWidthDesktop && 'xl:max-w-[var(--maxWidthDesktop)]',
        maxWidthTablet && 'md:max-w-[var(--maxWidthTablet)]'
      )}
      style={
        {
          aspectRatio,
          '--maxWidthDesktop': maxWidthDesktop && `${maxWidthDesktop}px`,
          '--maxWidthTablet': maxWidthDesktop && `${maxWidthTablet}px`,
        } as CSSProperties
      }
    >
      {renderImage({ alt, linkType, slug, src, target, url })}
    </div>
  );
};

/** image renderer */
const renderImage = ({ alt, linkType, slug, src, target, url }) => {
  if (linkType === 'Web' && url) {
    return (
      <a href={url} target={target}>
        <StyledImage src={src} alt={alt} fill />
      </a>
    );
  } else if (linkType === 'Document' && slug) {
    return (
      <LinkWrap href={`/${slug}`}>
        <StyledImage src={src} alt={alt} fill />
      </LinkWrap>
    );
  } else {
    return <StyledImage src={src} alt={alt} fill />;
  }
};

const StyledImage = ({ className, ...props }: ImageProps) => (
  <Image {...props} className={cn('image:object-cover h-full w-full', className)} />
);
