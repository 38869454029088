import {
  ChangeEvent,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  TextareaHTMLAttributes,
  useState,
} from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { Button } from '../layout/Button';
import { DisplayHeading } from '../typography/DisplayHeading';
import { BodyText } from '../typography/BodyText';

export type LeadFormProps = {
  /**
   * Form endpoint
   */
  endpoint: string;
  /**
   * Form ID used for anchoring
   */
  id?: string;
};

type FormState = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  telephone: string;
  message: string;
};

/**
 * Currently used to render a form that sends through corporate enquiries to the specified Formspree endpoint
 * @param endpoint  Used by Formspree to send the form data to the correct project
 */
export const LeadForm = ({ endpoint, id = 'contact-form', ...rest }: LeadFormProps) => {
  const [formValues, setFormValues] = useState<FormState>({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    telephone: '',
    message: '',
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formKey: keyof FormState
  ): void => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    const { value } = target;
    setFormValues((prev) => ({ ...prev, [formKey]: value }));
  };

  return (
    <div id={id} {...rest} className="w-full">
      <div className="mb-6 flex flex-col items-start gap-2">
        <DisplayHeading size="2" className="text-[22px]">
          Contact Us
        </DisplayHeading>
        <BodyText leading="relaxed">Our friendly team would love to hear from you.</BodyText>
      </div>
      <form action={endpoint} method="POST" className="flex flex-col gap-6 border-0 lg:gap-4">
        <div className="flex gap-4">
          <Label>
            First Name
            <Input
              type="text"
              name="first name"
              id="first-name"
              placeholder="First Name"
              required
              value={formValues.firstName}
              onChange={(e) => handleChange(e, 'firstName')}
            />
          </Label>
          <Label>
            Last Name
            <Input
              type="text"
              name="last name"
              id="last-name"
              placeholder="Last Name"
              required
              value={formValues.lastName}
              onChange={(e) => handleChange(e, 'lastName')}
            />
          </Label>
        </div>
        <Label>
          Company
          <Input
            type="text"
            name="company"
            id="company"
            placeholder="Company Name"
            required
            value={formValues.company}
            onChange={(e) => handleChange(e, 'company')}
          />
        </Label>
        <Label>
          Email Address
          <Input
            type="email"
            name="email"
            placeholder="Email Address"
            required
            value={formValues.email}
            onChange={(e) => handleChange(e, 'email')}
          />
        </Label>
        <Label>
          Contact Number
          <Input
            type="tel"
            name="telephone"
            id="telephone"
            placeholder="Contact Number"
            value={formValues.telephone}
            onChange={(e) => handleChange(e, 'telephone')}
          />
        </Label>
        <Label>
          Message
          <TextArea
            name="message"
            placeholder="Tell us more about what you’re looking to achieve"
            rows={8}
            required
            value={formValues.message}
            onChange={(e) => handleChange(e, 'message')}
          />
        </Label>
        <Button
          type="submit"
          variant="primary"
          className="hover:bg-background-primary-highlight focus:bg-background-primary p-4 text-base leading-none"
        >
          Get in touch
        </Button>
      </form>
    </div>
  );
};

const Input = ({ className, ...props }: InputHTMLAttributes<HTMLInputElement>) => (
  <input {...props} className={cn(styles.input, className)} />
);

const TextArea = ({ className, ...props }: TextareaHTMLAttributes<HTMLTextAreaElement>) => (
  <textarea {...props} className={cn(styles.input, className)} />
);

const Label = ({ className, children, ...props }: LabelHTMLAttributes<HTMLLabelElement>) => (
  <label {...props} className={cn(styles.label, className)}>
    {children}
  </label>
);

const styles = {
  input:
    'text-black border-border-neutral min-h-12 rounded border px-4 py-3 text-base leading-base font-normal placeholder:text-base placeholder:leading-normal placeholder:text-neutral-faded',
  label:
    'font-inter flex w-full min-w-0 flex-col gap-2 text-left text-sm font-semibold leading-normal',
};
