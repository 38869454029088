import { CorporateLeadForm as Component } from '@virginexperiencedays/components-v2/src/cms/CorporateLeadForm';
import type {
  PrismicMediaLink,
  PrismicWebLink,
} from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

type CorporateLeadFormProps = {
  slice: {
    primary: {
      endpoint: PrismicMediaLink | PrismicWebLink;
      overtitle: string;
      title: string;
      description: string;
    };
    slice_type: string;
  };
};

const CorporateLeadForm = ({ slice }: CorporateLeadFormProps) => {
  const {
    primary: { endpoint, overtitle, title, description },
  } = slice;

  return (
    <Component
      formEndpoint={endpoint.url}
      overTitle={overtitle}
      title={title}
      description={description}
    />
  );
};

export default CorporateLeadForm;
