// Components
import { UspGrid as Component } from '@virginexperiencedays/components-v2/src/UspGrid';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
// Types
import { ImageField } from '@prismicio/types';
import { PrismicContext } from '../../types/context';

export const ITEMS_TO_DISPLAY = 6;

type UspGridProps = {
  slice: {
    primary: {
      title_size: string;
    };
    items: {
      title: string;
      description: string;
      image: ImageField;
    }[];
  };
  context: PrismicContext;
};

const UspGrid = ({ slice, context }: UspGridProps) => {
  const {
    primary: { title_size },
    items: sliceItems,
  } = slice;
  const { isFaceted } = context;

  if (isFaceted || !sliceItems || sliceItems?.length < ITEMS_TO_DISPLAY) return null;

  const items = sliceItems.slice(0, ITEMS_TO_DISPLAY).map(({ title, description, image }) => ({
    title,
    description,
    imageUrl: image?.url,
    imageAlt: image?.alt,
  }));

  return (
    <Container>
      <Component titleAs={`h${title_size ?? '4'}` as any} items={items} />
    </Container>
  );
};

export default UspGrid;
