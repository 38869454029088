import { cn } from '@virginexperiencedays/tailwind';

import { CORPORATE_LEAD_FORM_IMAGE } from '../../constants/images';

import { Container } from '../../layout/Container';
import { Image } from '../../layout/Image';
import { LeadForm } from '../../LeadForm';
import { LinkButton } from '../../navigation/LinkButton';
import { DisplayHeading } from '../../typography/DisplayHeading';
import { BodyText } from '../../typography/BodyText';

type CorporateLeadFormProps = {
  formEndpoint: string;
  overTitle?: string;
  title?: string;
  description?: string;
};

export const CorporateLeadForm = ({
  formEndpoint,
  overTitle,
  title,
  description,
}: CorporateLeadFormProps) => {
  if (!formEndpoint) return null;

  return (
    <Container
      data-testid="corporate-lead-form"
      className="flex flex-col gap-6 lg:flex-row lg:gap-10"
    >
      <div
        className={cn(
          'w-full lg:max-w-[400px] lg:rounded-lg lg:px-6 lg:py-8',
          'lg:shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.06),_0px_20px_24px_-5px_rgba(0,_0,_0,_0.1)]'
        )}
      >
        {!!title && (
          <div
            data-testid="corporate-lead-form-header"
            className="mb-6 flex flex-col items-start gap-1 self-stretch lg:gap-2"
          >
            {!!overTitle && (
              <span
                className={cn(
                  'bg-background-primary font-inter rounded px-1 py-0.5 text-xs uppercase tracking-wider text-white',
                  'overflow-wrap-anywhere font-semibold leading-normal'
                )}
              >
                {overTitle}
              </span>
            )}
            <DisplayHeading as="h2">{title}</DisplayHeading>
            {!!description && <BodyText className="m-0">{description}</BodyText>}
          </div>
        )}
        <LeadForm endpoint={formEndpoint} />
      </div>
      <div className="flex flex-col gap-6 lg:mt-20 lg:flex-1 lg:items-center lg:gap-10">
        <Image
          dataTestId="corporate-lead-form-main-image"
          className="hidden h-auto lg:block"
          src={CORPORATE_LEAD_FORM_IMAGE.desktop}
          width={840}
          height={482}
          alt="Corporate Lead Form Image"
          priority
        />
        <p
          className={cn(
            'bg-background-page-faded flex flex-col items-center text-xl leading-normal',
            'm-0 w-full gap-x-1 gap-y-0 rounded px-2 py-4 text-center',
            'lg:order-1 lg:flex-row lg:flex-wrap lg:justify-center'
          )}
        >
          Looking to order a large amount?{' '}
          <span className="text-neutral-strong font-semibold">
            Call us today on:{' '}
            <span className="text-brand [&_>_a]:text-brand text-nowrap text-2xl font-bold leading-snug [&_>_a]:no-underline">
              0330 111 3030
            </span>
          </span>
        </p>
        <Image
          dataTestId="corporate-lead-form-mobile-image"
          className="block lg:hidden"
          src={CORPORATE_LEAD_FORM_IMAGE.mobile}
          width={992}
          height={554}
          alt="Corporate Lead Form Image"
        />
        <LinkButton
          href="/corporate-gifts"
          className="bg-background-primary hover:bg-background-primary-highlight focus:bg-background-primary block p-4 text-base leading-none text-white lg:w-[355px]"
        >
          Buy Now
        </LinkButton>
      </div>
    </Container>
  );
};
