import {
  CustomImage as Component,
  type CustomImageProps as ComponentProps,
} from '@virginexperiencedays/components-v2/src/cms/CustomImage';

type CustomImageProps = {
  slice: {
    items: ComponentProps['items'];
  };
};

const CustomImage = ({ slice }: CustomImageProps) => {
  const { items } = slice;
  return <Component items={items} />;
};

export default CustomImage;
